
















































import { Component, Vue } from 'vue-property-decorator';
import InviteFriendCardViewModel
  from '@/vue-app/view-models/components/my-investment-dashboard-v2/invite-friend-card-view-model';

@Component({
  name: 'InviteFriendCard',
  components: {
    InviteFriendModal: () => import('@/vue-app/components/home-dashboard/invite-friend/InviteFriendModal.vue'),
  },
})
export default class InviteFriendCard extends Vue {
  invite_friend_view_model = Vue.observable(new InviteFriendCardViewModel());
}
