import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class InviteFriendCardViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.my-investment-dashboard.invite-friend';

  show_invite_friend_modal = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  showInviteModal = () => {
    this.show_invite_friend_modal = true;
  }
}
